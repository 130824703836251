import React from 'react';
import { Document, Page,  Text, StyleSheet } from '@react-pdf/renderer';
import TableRow from './TableRow';


const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingTop: 30,
  },
  header: {
    fontSize: 16,
    marginBottom: 10, 
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 18,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const MyDocument = ({ token, data }) => ( 

    <Document pageMode='fullScreen'>
      <Page size="A4" style={styles.page}  >
        {data.map(item => (
          <TableRow
            key={item.id}
            nombre={item.nombre}
            sku={item.sku}
            cantidad={item.cantidad}
            deposito={item.deposito}
            ubicacion={item.ubicacion}
          />
        ))}
        
        <Text style={styles.footer} fixed>
           Generado por Dux Software
        </Text>
      
      </Page>
    </Document>
  
);

export default MyDocument;