import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import MyDocument from './MyDocument';
import LoadingScreen from './LoadingScreen'; //por ahora no anda despues lo veo
import logoCarga from '../logo-dux.png'; 
import { useLocation } from 'react-router-dom';

const PDFViewerComponent = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    setToken(tokenParam);
    console.log(token);

    setIsLoading(true); // Mostrar indicador de carga al inicio de la solicitud
    setError(null); // Limpiar cualquier error previo

    if (!token) {
      return; // Si el token está vacío, no hace la petición
    }
    fetch(`${window._env_.REACT_APP_BACKEND_URL}/remito/info-armado?token=${token}`)
      
      .then(response => {
        if (!response.ok) {
          throw new Error('No se pudo obtener la información');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false); // Ocultar indicador de carga cuando la solicitud se completa
      });
  }, [token]);

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={logoCarga} alt="React Logo" style={{ width: '200px' }} />
      </div>
  }

  if (error) {
    return <div>Error: {error}</div>; // Mostrar mensaje de error si ocurrió algún problema
  }

  // Renderizar el contenido si la solicitud se completó con éxito
  return (
    <div>
      {data && (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <MyDocument token={token} data={data} />
        </PDFViewer>
      )}
    </div>
  );
};

export default PDFViewerComponent;