import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom'; // Importa useParams
import ReactDOM from 'react-dom';
import PDFViewerComponent from './components/PDFViewerComponent';

document.title = 'Reporte de Armado';

const App = () => (
  <Router>
    <Routes>
      <Route path="/info-armado" element={<PDFViewerComponent />} />
    </Routes>
  </Router>
);

//ReactDOM.render(<App />, document.getElementById('root'));

export default App;
 