import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    h4: {
      fontSize: 12, 
      fontWeight: 'bold'
    },
    h5: {
      fontSize: 10
    },
    rowContainer: {
        flexDirection: 'row', 
        alignItems: 'center', 
        border: 1,  
        padding: 5,
        pageBreakInside: 'avoid',
      },
  });
  
const TableRow = ({id, nombre, sku, cantidad, deposito, ubicacion }) => (
    <View style={styles.rowContainer} >
      <View style={{ width: 20, height: 20, marginRight: 5, border: 1 }} break />
      <View style={{ flex: 1 }} break>
        <Text style={styles.h4}>{nombre}</Text>
        <Text style={styles.h5}>sku: {sku}</Text>
        <Text style={styles.h5}>Cantidad: {cantidad}</Text>
        <Text style={styles.h5}>Deposito: {deposito}</Text>
        <Text style={styles.h5}>Ubicacion: {ubicacion}</Text>
        
      </View>
    </View>
  );

export default TableRow;